import request from '@/axios/request'

export function getCompanyList(_params){
  return request({
    url: '/common/company',
    method: 'get',
    params: _params
  })
}

export function getEquipType(_params){
    return request({
        url: '/common/equip-type',
        method: 'get',
        params: _params
    }) 
}

// 每日数据
export function getDayData(_params){
    return request({
        url: '/index/day-data',
        method: 'get',
        params: _params
    }) 
}

// 数据总览
export function getPandectData(_params){
    return request({
        url: '/index/pandect',
        method: 'get',
        params: _params
    }) 
} 