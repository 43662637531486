import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: null,
    token: null,
    userCode: null,
    officeName: null,
    loginCode: null,

    companyId: null,
    equipType: null
  },
  getters: {
    equipInfo(state){
      if(state.equipType == null){
        let _info = JSON.parse(sessionStorage.getItem('equipInfo') || "{}")
        state.companyId = _info.companyId
        state.equipType = _info.equipType
      }
      return {
        companyId: state.companyId,
        equipType: state.equipType
      }
    },
    userInfo(state){
      if(state.userName == null){
        let _info = JSON.parse(sessionStorage.getItem('userInfo') || "{}")
        state.userName = _info.userName
        state.token = _info.token
        state.userCode = _info.userCode
        state.officeName = _info.officeName
        state.loginCode = _info.loginCode
      }
      return {
        userName: state.userName,
        token: state.token,
        userCode: state.userCode,
        officeName: state.officeName,
        loginCode: state.loginCode
      }
    }
  },
  mutations: {
    setUserInfo(state, params){
      state.userName = params.userName
      state.token = params.token
      state.userCode = params.userCode
      state.officeName = params.officeName,
      state.loginCode = params.loginCode
      sessionStorage.setItem('userInfo', JSON.stringify(params))
      sessionStorage.setItem('loginCode', params.loginCode)
    },
    setEquipInfo(state, params){
      state.companyId = params.companyId || state.companyId
      state.equipType = params.equipType || state.equipType
      sessionStorage.setItem('equipInfo', JSON.stringify({
        companyId: state.companyId,
        equipType: state.equipType
      }))
    }
  },
  actions: {
  },
  modules: {
  }
})
