import request from '@/axios/request'

// 分段调试得分
export function getStepInfo(_params){
    return request({
      url: '/debug-report/step-info',
      method: 'get',
      params: _params
    })
  }

// 设备列表
export function getEquipList(_params){
    return request({
      url: '/equip/page',
      method: 'get',
      params: _params
    })
  }

  
// 通过车型查询系列
export function getEquipModel(_params){
    return request({
        url: '/common/equip-model',
        method: 'get',
        params: _params
      })
}

// 查询设备详情
export function getEquipDetail(_params){
    return request({
        url: '/equip/detail',
        method: 'get',
        params: _params
      })
}

// 参数合规率
export function getEquipParamComplance(_params){
    return request({
        url: '/equip/paramComplance',
        method: 'get',
        params: _params
      })
}


// 数据准确率
export function getEquipParamAccuracy(_params){
    return request({
        url: '/equip/paramAccuracy',
        method: 'get',
        params: _params
      })
}

// 在检票
export function getEquipError(_params){
    return request({
        url: '/equip/getError',
        method: 'get',
        params: _params
      })
}

// 调试得分
export function getScoreTask(_params){
    return request({
        url: '/equip/getScoreTask',
        method: 'get',
        params: _params
      })
}

// 调试画像列表查询
export function getPortraitList(_params){
  return request({
      url: '/portrait-thrid/list',
      method: 'get',
      params: _params
    })
}

// 数据应用评价列表
export function getAccuracyList(_params){
  return request({
      url: '/accuracy/list',
      method: 'get',
      params: _params
    })
}

// 调试数据应用评价详情查询(准确率)
export function getAccDetail(_params){
  return request({
      url: '/accuracy/acc-detail',
      method: 'get',
      params: _params
    })
}

// 调试数据应用评价详情查询(齐全率)
export function getCompleteDetail(_params){
  return request({
      url: '/accuracy/complete-detail',
      method: 'get',
      params: _params
    })
}

// 调试画像详情查询
export function getPortraitDetail(_params){
  return request({
      url: '/portrait-thrid/detail',
      method: 'get',
      params: _params
    })
}

// 工人排行总览查询
export function getWorkerRankIndex(_params){
  return request({
      url: '/worker-rank/index',
      method: 'get',
      params: _params
    })
}

// 工人排行查询
export function getWorkerRankStep(_params){
  return request({
      url: '/worker-rank/step_rank',
      method: 'get',
      params: _params
    })
}

// 工人实时看板查询
export function getWorkerOrderRank(_params){
  return request({
      url: '/worker-order/rank',
      method: 'get',
      params: _params
    })
}

// 获取当日待调试车辆
export function getWaitingDebugEquip(_params){
  return request({
      url: '/equip/waiting-debug-equip',
      method: 'get',
      params: _params
    })
}

// 任务指派
export function sendEquipWorkMsg(_params){
  return request({
      url: '/equip-work-msg/send',
      method: 'post',
      data: _params
    })
}

// 故障检出统计
export function debugErrorStat(_params){
  return request({
      url: '/debug-error/stat',
      method: 'get',
      params: _params
    })
}

// 故障检出查询
export function debugErrorQuery(_params){
  return request({
      url: '/debug-error/query',
      method: 'get',
      params: _params
    })
}


