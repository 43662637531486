import axios from 'axios'
import store from '@/store'
import router from '@/router/index.js'
import {Notify} from 'vant'

// create an axios instance
const service = axios.create({
    baseURL: "/debugonline/app/", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
  })
  
  // request interceptor
  service.interceptors.request.use(
    config => {
      if (store.getters.userInfo.userCode) {
        config.headers['X-User-Id'] = store.getters.userInfo.userCode
      }
      return config
    },
    error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )
  
  // response interceptor
  service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */
  
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response.data
      if(res.code == 401){
        Notify({
          message: res.message || "请重新登录！",
          type: 'danger',
          duration: 5 * 1000
        })
        
        router.push({name:'Login'})
        return Promise.reject(res.message)
      }else if(res.code && (res.code != 200) && (res.code != 302) && (res.code != 20007)){
        Notify({
          message: res.code + "：" + (res.message || "服务响应出错！"),
          type: 'danger',
          duration: 5 * 1000
        })
        return Promise.reject(res.message)
      }
      return res
    },
    error => {
      Notify({
        message: error.message,
        type: 'danger',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  )
  
  export default service
