<template>
  <div id="app">

    <van-nav-bar left-text="返回" left-arrow :title="pageinfo.title" v-show="pageinfo.subpage" @click-left="onPageBack" class="my-navbar">
    </van-nav-bar>
    
    <router-view :style="{'padding-bottom': (pageinfo.subpage? 0 : '60px')}"/>

    <van-tabbar v-model="active" v-show="!pageinfo.subpage" route>
      <van-tabbar-item icon="home-o" :to="{path: '/home'}">首页</van-tabbar-item>
      <van-tabbar-item icon="chat-o" :badge="null" :to="{path: '/message'}">消息</van-tabbar-item>
      <van-tabbar-item icon="friends-o" :to="{path: '/me'}">我的</van-tabbar-item>
    </van-tabbar>

    <svg xmlns="http://www.w3.org/2000/svg" height="0">
      <defs>
          <linearGradient id="green_linear" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stop-color="#9CD84C"/>
                <stop offset="100%" stop-color="#0E9F6E"/>
          </linearGradient>
          <linearGradient id="red_linear" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stop-color="#f66499"/>
                <stop offset="100%" stop-color="#d6365b"/>
          </linearGradient>
          <linearGradient id="blue_linear" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stop-color="#377bf7"/>
                <stop offset="100%" stop-color="#1b3fda"/>
          </linearGradient>
          <linearGradient id="yellow_linear" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stop-color="#fcd72a"/>
                <stop offset="100%" stop-color="#f79a21"/>
          </linearGradient>
          <filter id="gaussian_blur" width="200%" x="-50%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10"></feGaussianBlur>
          </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  data(){
    return {
      active: 0
    }
  },
  computed: {
    pageinfo(){
      return this.$route.meta || {}
    }
  },
  mounted(){
  },
  methods: {
    onPageBack(){
      if(window.history.length <= 1){
        this.$router.push({path: '/'})
      }else{
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss">
html{  
  //background: #ddd;
  background: #f2f2f2;
}
#app {
  font-family: PingFangSC-Medium, PingFang SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #595959;
}

.round-box{
  margin: .625rem .9375rem;
  background: #fff;
  border-radius: 10px;
  padding: .9375rem;
}

.rect-box{
  margin: .625rem 0;
  background: #fff;
  padding: .9375rem;
}

.flex-row{
    display: flex;
    justify-content: space-around;
}
   
.filter-line2 .van-dropdown-menu__bar{
    background: transparent;
    box-shadow: none;
}
.filter-line2 .van-dropdown-menu__title{
    color: #bfbfbf;
    font-size: 12px;
}
.info-line2 .van-badge{
    border-width: 0;
    top: 4px;
}

  .filter-botton{
    font-size: 15px;
    font-weight: 500;
    border-radius: 22px;
    padding: .5rem 1.25rem;
    color: #8c8c8c;
    
    &.active{
      background: linear-gradient(135deg, #73b9fc 0%, #3f83f8 100%);
      color: #fff;
    }
  }
  .small-gray-text{
    font-size: 12px;
    color: #8c8c8c;
  }
  .middle-black-text{
    color: #595959;
    font-size: 17px;
    font-weight: 500;
  }
  .blue-link-text{
    color: #3f83f8;
    font-size: 14px;
  }
  .talign-left{
    text-align: left;
  }
  .round-gray-box{
    background: #f7f7f7;
    border-radius: 5px;
    font-size: 14px;
    color: #8c8c8c;
  }

  .my-collase-item{
      margin: 10px 15px;
      border-radius: 10px;
      background: #fff;
      padding: 5px;

      .van-collapse-item__content{
        padding: 12px 0;
      }

      .collapse-item-header{
          display: flex;
          align-items: center;
      } 
      .collapse-item-con{
          position: relative;
          img{
              width: 80%;
          }
          .num-figure{
              color: #0e9f6e;
              font-size: 14px;
              font-weight: bold;
          }
          .desc-figure{
              font-size: 11px;
              color: #8c8c8c;
          }
          .absol-item{
              position: absolute;
              width: 80px;
          }
      }     
  }

  .colorful-text-bar{
    >.text1{
          width: 24px;
          height: 24px;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          line-height: 24px;
      }
      >.text2{
          text-align: left;
          flex-grow: 1;
          color: #333333;
          font-size: 17px;
          margin-left: 10px;
      }
      >.text3{
          font-size: 17px;
          font-weight: bold;
          margin-right: 5px;
      }
      &.color-style-blue{
          >.text1{
              background: linear-gradient(135deg, #73b9fc 0%, #3f83f8 100%);
          }
          >.text3{
              color: #3f83f8;
          }
      }
      &.color-style-green{
          >.text1{
              background: linear-gradient(135deg, #0e9f6e 0%, #9cd84c 100%);
          }
          >.text3{
              color: #0e9f6e;
          }
      }
      &.color-style-yellow{
          >.text1{
              background: linear-gradient(135deg, #e3a008 0%, #ff8a4c 100%);
          }
          >.text3{
              color: #e3a008;
          }
      }
      &.color-style-red{
          >.text1{
              background: linear-gradient(135deg, #f05252 0%, #f17eb8 100%);
          }
          >.text3{
              color: #f05252;
          }
      }
  }
  
  .my-navbar{
    &.van-nav-bar{
      background-color: #3f83f8;
      .van-icon{
        color: #fff;
      }
    }
    .van-nav-bar__text, .van-nav-bar__title{
      color: #fff;
    }
  }

  .g-tile-row{
    display: flex;
    justify-content: space-around;
    .num{
      font-size: 32px;
      color: #3f83f8;
      font-weight: bold;
      &.num-idle{
        color: #8c8c8c;
      }
    }
    .text{
      color: #595959;
      font-size: 15px;
    }
  }
</style>

