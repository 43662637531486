<template>
    <div class="score-tag">
        <img src="../assets/图标-白-评分@2x.png" alt="">
        <span>调试得分：{{info.num | formatDecimal(0)}}分</span>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.score-tag{
    height: 28px;
    font-size: 12px;
    background: linear-gradient(315deg, #0e9f6e 0%, #9cd84c 100%);
    border-radius: 100px 0px 0px 100px;
    color: #fff;
    padding: 0 5px;
    display: flex;
    align-items: center;
    img{
        width: 20px;
        height: 20px;
        padding: 0 5px 0 10px;
    }
}
</style>