export function formatDate(_date, _type) {
    if (_type == "month") {
        return `${(_date.getMonth() + 1)}/${_date.getDate()}`
    } else {
        return `${_date.getFullYear()}-${('0' + (_date.getMonth() + 1)).substr(-2)}-${('0' + _date.getDate()).substr(-2)}`
    }
}

export function getLastMonth(_date) {
    let now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth()
    if (month == 0) {
        return (year - 1) + "-12"
    } else {
        return year + "-" + (month < 10 ? ('0' + month) : month)
    }
}

export function getWeekTime(n) {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDay(); //返回星期几的某一天;
    n = day == 0 ? n + 6 : n + (day - 1)
    now.setDate(now.getDate() - n);
    let date = now.getDate();
    var s = year + "-" + (month < 10 ? ('0' + month) : month) + "-" + (date < 10 ? ('0' + date) : date);
    return s;
}

export function getTimeAgo(sourceTime) {
    var mistiming = Math.round(new Date() / 1000) - sourceTime;
    var arrr = ['年', '个月', '星期', '天', '小时', '分钟', '秒'];
    var arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    for (var i = 0; i < arrn.length; i++) {
        var inm = Math.floor(mistiming / arrn[i]);
        if (inm != 0) {
            return inm + arrr[i] + '前';
        }
    }
}