<template>
    <div>
        <div class="top-bg">
            <img src="../../assets/背景色@2x.png" alt="">
        </div>
        <div style="z-index: 1;position: relative;">
            <div class="top-line">
                <img class="small-logo" src="../../assets/Logo@2x.png" alt="">
                <span class="title-text">集团智能调试互联系统</span>
                <span class="welcome-text"><span style="opacity: 0.5;">欢迎使用，</span>{{userInfo.userName}}</span>
            </div>
            <div class="filter-line">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="filter.companyId" :options="filter.companyIdOpts"></van-dropdown-item>
                    <van-dropdown-item v-model="filter.equipType" :options="filter.equipTypeOpts"></van-dropdown-item>
                </van-dropdown-menu>
            </div>

            <div class="messageCon" v-if="messageShow">
                <div class="firstCon" @click="toDeviceInfoFormMsg">
                    <img class="pisIcon" src="../../assets/pis.png" alt="">
                    <span class="msgContent">{{messageInfo.msgContent}}</span>
                    <span class="timeShow">{{messageInfo.timeDiff}}</span>
                </div>
                <div class="iconShow" @click="toMessagePage">
                    <div class="numShow">{{noMessage}}</div>
                </div>
            </div>
            <!-- <div class="info-line">
                <div class="info-bar"><img style="width: 16px; height: 16px;margin-right: 4px;" src="../../assets/图标-白-注意@2x.png" alt=""><span>BC54453921 调试油耗超标</span>
                <span style="flex-grow: 1; text-align: right;">3分钟前</span></div>
                <div>
                    <van-badge :content="3">
                        <img style="width: 24px; height: 26px;" src="../../assets/图标-导航-选中-消息@2x.png" alt="">
                    </van-badge>
                </div>
            </div> -->
            <div class="round-box tiles-box">
                <div class="flex-row">
                    <div class="filter-botton" :class="{active: calendarOpt.overview.tabIndex == 2}" @click="calendarOpt.overview.tabIndex = 2">昨日</div>
                    <div class="filter-botton" :class="{active: calendarOpt.overview.tabIndex == 1}" @click="calendarOpt.overview.tabIndex = 1">本月</div>
                    <div class="filter-botton" :class="{active: calendarOpt.overview.tabIndex == 3}" @click="overviewDefinedDateFilter">{{calendarOpt.overview.text}}</div>
                </div>
                <div class="flex-row row-items">
                    <div class="info-item">
                        <div class="num" style="color: #3f83f8;">{{pandectData.debugedNum || 0}}</div>
                        <div>调试完成设备</div>
                    </div>
                    <div class="info-item">
                        <div class="num" style="color: #f05252;">{{pandectData.errorNum || 0}}</div>
                        <div>调试异常设备</div>
                    </div>
                    <div class="info-item">
                        <div class="num" style="color: #e3a008;">{{(pandectData.portraitScore || 0) | formatDecimal}}</div>
                        <div>画像图评分</div>
                    </div>
                    <div class="info-item">
                        <div class="num" style="color: #0e9f6e;">{{(pandectData.accuracyScore || 0) | formatDecimal}}</div>
                        <div>数据应用评分</div>
                    </div>
                </div>
            </div>

            <div class="round-box box2">
                <div>
                    <div>
                        <van-search v-model="searchVal" label="设备查询" placeholder="请输入设备完整编码" maxlength="30" style="padding: 0px" @search="onSearch"></van-search>
                    </div>
                    <div class="row-items">
                        <div>
                            <router-link :to="{path: '/home/debugList'}">
                                <div><img src="../../assets/图标-菜单-调试列表@2x.png" alt=""></div>
                                <div class="item-text">调试列表</div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{path: '/home/deviceList'}">
                                <div><img src="../../assets/图标-菜单-设备列表@2x.png" alt=""></div>
                                <div class="item-text">设备列表</div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{path: '/home/debugPortrait'}">
                                <div><img src="../../assets/图标-菜单-调试画像图@2x.png" alt=""></div>
                                <div class="item-text">调试画像图</div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{path: '/home/evaluationDebug'}">
                                <div><img src="../../assets/图标-菜单-数据应用评价@2x.png" alt=""></div>
                                <div class="item-text">数据应用评价</div>
                            </router-link>
                        </div>
                    </div>                    
                    <div class="row-items">
                        <div>
                            <router-link :to="{path: '/home/workerRealtime'}">
                                <div><img src="../../assets/图标-菜单-工人实时看板@2x.png" alt=""></div>
                                <div class="item-text">工人实时看板</div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{path: '/home/workerRating'}">
                                <div><img src="../../assets/图标-菜单-工人排行榜@2x.png" alt=""></div>
                                <div class="item-text">工人排行榜</div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{path: '/home/ticketList'}">
                                <div><img src="../../assets/图标-菜单-在检票查询@2x.png" alt=""></div>
                                <div class="item-text">在检票查询</div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{path: '/home/alarmSetting'}">
                                <div><img src="../../assets/图标-菜单-报警设置@2x.png" alt=""></div>
                                <div class="item-text">报警设置</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="round-box tiles-box">
                <div class="flex-row">
                    <div class="filter-botton" :class="{active: calendarOpt.debug.tabIndex == 0}" @click="calendarOpt.debug.tabIndex = 0">今日调试设备</div>
                    <div class="filter-botton" :class="{active: calendarOpt.debug.tabIndex == 1}" @click="debugDefinedDateFilter">{{calendarOpt.debug.text}}</div>
                </div>
                <div class="flex-row row-items">
                    <div class="info-item">
                        <div class="num" style="color: #0e9f6e;">{{dayDebugData.debugWaitingNum || 0}}</div>
                        <div>等待调试</div>
                    </div>
                    <div class="info-item">
                        <div class="num" style="color: #3f83f8;">{{dayDebugData.debugingNum || 0}}</div>
                        <div>正在调试</div>
                    </div>
                    <div class="info-item">
                        <div class="num" style="color: #e3a008;">{{dayDebugData.debugedNum || 0}}</div>
                        <div>调试完成</div>
                    </div>
                </div>
            </div>

            <div>                
                <device-info v-for="item in deviceList" :key="item.equipCode" :item="item"  @click.native="toDeviceInfo(item)"></device-info>
                <div class="round-box blue-link-text" @click="$router.push({path: '/home/deviceList'})">查看更多设备</div>   
            </div>
        </div>

        <van-calendar v-model="calendarOpt.overview.showCalendar" :max-date="calendarOpt.maxDate" :min-date="calendarOpt.minDate" 
        :default-date="calendarOpt.defaultDate" type="range" @confirm="onOverviewCalendarConfirm" :lazy-render="false"></van-calendar>
        <van-calendar v-model="calendarOpt.debug.showCalendar" :max-date="calendarOpt.maxDate" :min-date="calendarOpt.minDate" 
        @confirm="onDebugCalendarConfirm" :lazy-render="false"></van-calendar>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import deviceInfo from '@/components/DeviceInfo'
import {formatDate,getTimeAgo} from '@/utils/common'
import { getNoMessageFrist,getNoMessageNum,setReadMessage } from '@/axios/message'
import * as api from "@/axios/home"
import {Notify} from 'vant'
import {getEquipList, getEquipDetail} from "@/axios/device"

export default {
    components: {deviceInfo},
    computed: {
        ...mapGetters(['userInfo', 'equipInfo'])
    },
    data(){
        return {
            searchVal: "",
            filter: {
                companyId: null,
                equipType: null,
                companyIdOpts: [],
                equipTypeOpts: []
            },
            deviceList: [],
            calendarOpt: {
                maxDate: new Date(),
                minDate: new Date(new Date().getFullYear() - 1, 0, 1),
                defaultDate: [new Date(new Date() - 24 * 3600 * 1000), new Date()],                
                overview: {
                    tabIndex: 2,
                    showCalendar: false,
                    text: "自定义时间",
                    startDate: null,
                    endDate: null
                },
                debug: {
                    tabIndex: 0,
                    showCalendar: false,
                    text: "其它日期",
                    startDate: null
                }
            },
            // 每日设备调试数据
            dayDebugData: {},
            // 数据总览
            pandectData: {},
            userAccount: '',
            noMessage: '',
            messageInfo: '',
            messageShow: true
        }
    },
    watch: {
        "filter.companyId": function(_val){
            if(_val){
                this.setEquipInfo({companyId: _val})
                this.getEquipTypesByCompany()
            }
        },
        // Hack: 此处以不同公司不生产同一设备为前提
        "filter.equipType": function(_val){
            if(_val){                
                this.setEquipInfo({equipType: _val})
                this.updateData()
            }
        },
        "calendarOpt.debug.text": function(_old, _new){
            this.getDayData()
        },
        "calendarOpt.debug.tabIndex": function(){
            this.getDayData()
        },
        "calendarOpt.overview.text": function(_old, _new){
            this.getPandectData()
        },
        "calendarOpt.overview.tabIndex": function(){
            this.getPandectData()
        }
    },
    mounted(){
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        this.userAccount = userInfo.loginCode
        getNoMessageFrist({userAccount:this.userAccount}).then(res=>{
            this.messageInfo = res.content
            this.messageInfo.timeAgo = getTimeAgo(Date.parse(new Date(res.content.createDate))/1000)
        })
        getNoMessageNum({userAccount: this.userAccount}).then(res=>{
            this.noMessage = res.content < 99?res.content:'99+'
            this.messageShow = res.content?true:false
        })
    },
    created(){
        this.filter.companyId = this.equipInfo.companyId
        this.filter.equipType = this.equipInfo.equipType
        
        // 公司可选列表
        api.getCompanyList().then(res=>{
            let _arr = []
            res.content.forEach(_itm => {
                _arr.push({text: _itm.alias, value: _itm.id})
            })
            this.filter.companyIdOpts = Object.freeze(_arr)
            if(!this.filter.companyId){
                this.filter.companyId = _arr[0].value
            }
        })
    },
    methods: {
        ...mapMutations([
            'setEquipInfo'
        ]),
        onSearch(_val){
            if(!_val){
                return
            }            
            getEquipDetail({
                equipCode: _val
            }).then(res=>{
                if(res.content){
                    this.$router.push({path: `/home/deviceDetail/${_val}`})
                }else{
                    Notify({
                        message: "查询不到此设备！",
                        type: 'danger',
                        duration: 5 * 1000
                    })
                }
            })
        },
        // 根据公司名获取可选equipType
        getEquipTypesByCompany(){
            // 根据公司名获取
            return new Promise((resolve)=>{
                api.getEquipType({
                    companyId: this.filter.companyId
                }).then(res=>{
                    let _arr = []
                    res.content.forEach(_itm => {
                        _arr.push({text: _itm.name, value: _itm.sqlStr})
                    })
                    if(!this.filter.equipType || !_arr.some(_itm=>_itm.value == this.filter.equipType)){
                        this.filter.equipType = _arr[0].value
                    }
                    this.filter.equipTypeOpts = Object.freeze(_arr)
                }).finally(()=>{
                    resolve()
                })                
            })
        },
        // 更新页面整体数据
        updateData(){
            this.getDayData()
            this.getPandectData()
        },
        // 获取总览数据
        getPandectData(){
            if(!this.filter.equipType){
                return
            }
            let _params = {
                companyId: this.filter.companyId,
                equipType: this.filter.equipType,
                type: this.calendarOpt.overview.tabIndex
            }
            switch(this.calendarOpt.overview.tabIndex.toString()){
                case "1": _params.date = formatDate(new Date()).substr(0,7); break;
                case "2": _params.date = formatDate(new Date(new Date() - 24 * 3600 * 1000)); break;
                case "3": 
                    if(this.calendarOpt.overview.startDate){
                        _params.startTime = formatDate(this.calendarOpt.overview.startDate)
                        _params.endTime = formatDate(this.calendarOpt.overview.endDate)
                    }else{
                        return
                    }
                    break;
            }
            api.getPandectData(_params).then(res=>{
                this.pandectData = res.content
            })
        },        
        // 根据日期获取每日调试数据
        getDayData(){
            if(!this.filter.equipType){
                return
            }
            if(this.calendarOpt.debug.tabIndex == 1 && !this.calendarOpt.debug.startDate){
                return
            }
            let _date = this.calendarOpt.debug.tabIndex == 0? formatDate(new Date()) : formatDate(this.calendarOpt.debug.startDate)
            api.getDayData({
                companyId: this.filter.companyId,
                equipType: this.filter.equipType,
                startDate: _date
            }).then(res=>{
                this.dayDebugData = res.content
            })
            
            getEquipList({
                companyId: this.filter.companyId,
                equipType: this.filter.equipType,
                pageNo: 1,
                pageSize: 20,
                type: 2, // 类型1月 2日 3自定义
                date: _date,
                series: ""
            }).then(res => {
                this.deviceList = res.content.list || []
            })
        },
        overviewDefinedDateFilter(){
            this.calendarOpt.overview.tabIndex = 3
            this.calendarOpt.overview.showCalendar = true
        },
        debugDefinedDateFilter(){
            this.calendarOpt.debug.tabIndex = 1
            this.calendarOpt.debug.showCalendar = true
        },
        onOverviewCalendarConfirm(_date){
            const [start, end] = _date
            this.calendarOpt["overview"].showCalendar = false
            this.calendarOpt["overview"].startDate = start
            this.calendarOpt["overview"].endDate = end
            this.calendarOpt["overview"].text = formatDate(start, "month") + " - " + formatDate(end, "month")
        },
        onDebugCalendarConfirm(_date){
            this.calendarOpt["debug"].showCalendar = false
            this.calendarOpt["debug"].startDate = _date
            this.calendarOpt["debug"].text = formatDate(_date)
        },
        toDeviceInfo(info){
            this.$router.push({path: `/home/deviceDetail/${info.equipCode}`})
        },
        toDeviceInfoFormMsg(){
            setReadMessage({msgId:this.messageInfo.msgId}).then(res=>{
                if(res.code == 200){
                    this.$router.push({path: `/home/deviceDetail/${this.messageInfo.equipCode}`})
                }
            }) 
        },
        toMessagePage(){
            this.$router.push({path: '/message'})
        },
    }
}
</script>

<style lang="scss">    
    .filter-line .van-dropdown-menu__bar{
        background: rgba(255,255,255,0.15);
        margin: 0 .9375rem .625rem;
        border-radius: .625rem;
    }
    .filter-line .van-dropdown-menu__title{
        color: #fff;
    }
    .info-line .van-badge{
        border-width: 0;
        top: 4px;
    }
</style>

<style lang="scss" scoped>
    .top-bg{
        width: 100%;
        // height: 17rem;
        height: 17rem;
        position: absolute;
        top: 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .top-line{
        padding: .625rem .9375rem;
        display: flex;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        .small-logo{
            width: 1.5rem;
            height: 1.5rem;
        }
        .title-text{
            margin-left: .375rem;
            flex-grow: 1;
            text-align: left;
        }
        .welcome-text{
            float: right;
            font-size: 14px;
        }
    }
    
    .info-line{
        margin: 0 .9375rem 0.5rem;
        display: flex;        
        align-items: center;
        color: #fff;
        font-size: 13px;
        .info-bar{
            display: flex;
            margin-right: .3125rem;
            padding: .6875rem 1.15rem;
            background: linear-gradient(135deg, #faca15 0%, #e3a008 100%);
            border-radius: 18px;
            flex-grow: 1;
        }        
    }
    .tiles-box{
        .row-items{
            margin-top: 1rem;
            font-size: 11px;
            .info-item{
                width: 4.375rem;
                flex-grow: 1;
            }
            .num{
                font-size: 24px;
                font-weight: bold;
            }
        }
    }
    .box2{
        .row-items{
            display: flex;
            >div{
                width: 6rem;
                flex-grow: 1;
                margin: .625rem 0;
            }
            img{
                width: 56px;
            }
            .item-text{
                font-size: 12px;
                margin-top: -0.875rem;
                color: #595959;
            }
        }
    }
    .messageCon{
        // width: 100%;
        // margin: .625rem .9375rem;
        background: rgba(0,0,0,0);
        border-radius: 10px;
        padding: .9375rem;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .firstCon{
            width: 85%;
            height: 36px;
            background: linear-gradient(135deg, #FACA15 0%, #E3A008 100%);
            box-shadow: 0px 16px 16px 0px rgba(194, 120, 3, 0.05), 0px 10px 14px 0px rgba(194, 120, 3, 0.1), 0px 5px 12px 0px rgba(194, 120, 3, 0.1);
            border-radius: 18px;
            display: flex;
            align-items: center;
            justify-content: left;
            padding-left: 16px;
            position: relative;
            .pisIcon{
                width: 14px;
                height: 14px;
            }
            span{
                font-size: 12px;
                color: #fff;
            }
            .msgContent{
                width: 65%;
                margin-left: 9px  ;
                white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
                text-align: left;
            }
            .timeShow{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
            }
        }
        .iconShow{
            width: 24px;
            height: 22px;
            background-image: url('../../assets/message.png');
            background-size: 100% 100%;
            position: relative;
            .numShow{
                position: absolute;
                height: 20px;
                width: auto;
                min-width: 20px;
                border-radius: 10px;
                background-color: #F05252;
                color: #fff;
                font-size: 12px;
                line-height: 20px;
                text-align: center;
                top: -50%;
                right: -50%;
                padding: 0 3px;
            }
        }
    }
</style>