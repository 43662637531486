import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import store from '@/store/index'
import {login} from '@/axios/login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
    //redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/home/deviceList',
    component: () => import(/* webpackChunkName: "deviceList" */ '../views/home/deviceList.vue'),
    meta: {subpage: true, title: "设备列表"}
  },
  {
    path: '/home/debugList',
    component: () => import(/* webpackChunkName: "debugList" */ '../views/home/debugList.vue'),
    meta: {subpage: true, title: "调试列表"}
  },
  {
    path: '/home/deviceDetail/:equipCode',
    component: () => import(/* webpackChunkName: "deviceDetail" */ '../views/home/deviceDetail.vue'),
    meta: {subpage: true, title: "设备详情"}
  },
  {
    path: '/home/debugReport/:equipCode',
    component: () => import(/* webpackChunkName: "debugReport" */ '../views/home/debugReport.vue'),
    meta: {subpage: true, title: "调试得分报表"}
  },
  {
    path: '/home/debugPortrait',
    component: () => import(/* webpackChunkName: "debugPortrait" */ '../views/home/debugPortrait.vue'),
    meta: {subpage: true, title: "调试画像图系统"}
  },
  {
    path: '/home/paramsException',
    component: () => import(/* webpackChunkName: "paramsException" */ '../views/home/paramsException.vue'),
    meta: {subpage: true, title: "查看异常参数"}
  },
  {
    path: '/home/assessRuleDoc',
    component: () => import(/* webpackChunkName: "assessRuleDoc" */ '../views/home/assessRuleDoc.vue'),
    meta: {subpage: true, title: "考核规则"}
  },
  {
    path: '/home/evaluationDebug',
    component: () => import(/* webpackChunkName: "evaluationDebug" */ '../views/home/evaluationDebug.vue'),
    meta: {subpage: true, title: "调试数据应用评价系统"}
  },
  {
    path: '/home/evaluationException',
    component: () => import(/* webpackChunkName: "evaluationException" */ '../views/home/evaluationException.vue'),
    meta: {subpage: true, title: "查看异常参数"}
  },
  {
    path: '/home/workerRealtime',
    component: () => import(/* webpackChunkName: "workerRealtime" */ '../views/home/workerRealtime.vue'),
    meta: {subpage: true, title: "工人实时看板"}
  },
  {
    path: '/home/workerAssign',
    component: () => import(/* webpackChunkName: "workerAssign" */ '../views/home/workerAssign.vue'),
    meta: {subpage: true, title: "调试设备指派"}
  },
  {
    path: '/home/workerRating',
    component: () => import(/* webpackChunkName: "workerRating" */ '../views/home/workerRating.vue'),
    meta: {subpage: true, title: "工人排行榜"}
  },
  {
    path: '/home/ticketList',
    component: () => import(/* webpackChunkName: "ticketList" */ '../views/home/ticketList.vue'),
    meta: {subpage: true, title: "在检票查询"}
  },
  {
    path: '/home/alarmSetting',
    component: () => import(/* webpackChunkName: "alarmSetting" */ '../views/home/alarmSetting.vue'),
    meta: {subpage: true, title: "报警设置"}
  },
  {
    path: '/message',
    component: () => import(/* webpackChunkName: "message" */ '../views/message/index.vue')
  },
  {
    path: '/me',
    component: () => import(/* webpackChunkName: "me" */ '../views/me/index.vue')
  },
  {
    path: '/me/userList',
    component: () => import(/* webpackChunkName: "userList" */ '../views/me/userList.vue'),
    meta: {subpage: true, title: "调试班组"}
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
    if(store.getters.userInfo.token){
      next()
    }else{      
      let _code = null
      let _search = location.search
      if(_search){
        _search = _search.substring(1)
        let _vars = _search.split("&")
        for(let i = 0; i < _vars.length; i++){
          let _pair = _vars[i].split("=")
          if(_pair[0] == "code"){
            _code = _pair[1]
          }
        }
      }
      console.log('--login---')
      login({
        code: _code
      }).then((res)=>{
        console.log("---here---")
        
        console.log(res)
        if(res.code == 302 || res.code == 20007){
          console.log("---here---")
          let _redirect = "https://debugonline.test.rootcloudapp.com"
          if(process.env.NODE_ENV == "development"){
            _redirect = "http://10.8.211.204:8089"
          }
          location.href=`https://open.work.sany.com.cn/open-apis/authen/v1/index?redirect_uri=${_redirect}&app_id=cli_a1820a0066a1d076&state=1`
        }else if(res.code == 200){
          store.commit('setUserInfo', res.content)
          next()
        }
      })
    }
})

export default router
