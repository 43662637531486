import request from '@/axios/request'

// 消息列表
export function getWarnMessageList(_params){
    return request({
      url: '/equip-warning-msg/page',
      method: 'get',
      params: _params
    })
  }

// 获取未读消息数量
export function getNoMessageNum(_params){
    return request({
      url: '/equip-warning-msg/un-read/num',
      method: 'get',
      params: _params
    })
  }

// 获取未读消息第一条
export function getNoMessageFrist(_params){
    return request({
      url: '/equip-warning-msg/un-read',
      method: 'get',
      params: _params
    })
  }

// 设置已读
export function setReadMessage(data){
    return request({
      url: '/equip-warning-msg/read',
      method: 'post',
      data
    })
  }
// 设置全部已读
export function setReadAllMessage(data){
    return request({
      url: '/equip-warning-msg/read-all',
      method: 'post',
      data
    })
  }