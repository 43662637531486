import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.config.productionTip = false
Vue.use(Vant)

Vue.filter('formatDecimal', function(value, digit, unit){
  if(digit == null){
    digit = 2
  }  
  let _num = parseFloat(value)
  return isNaN(_num)? '-' : _num.toFixed(digit) + (unit || '')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
