import request from '@/axios/request'

export function login(_params){
    console.log(_params)
  return request({
    url: '/auth/pre-login',    
    // method: 'get',
    // params: _params
    method: 'get',
    params: _params
    // {
    //   account: "tangz43",
    //   password: "318@Sany.com"
    // }
  })
}