<template>
    <div class="round-box device-item" @click="itemClick">
        <div class="flex-row">
            <div class="img-con">    
                <img v-if="deviceImageUrl" :src="deviceImageUrl" alt="">
                <img class="empty" v-else src="../assets/图标-黑-图片@2x.png" alt="">
            </div>
            <div class="mid-con">
                <div class="item-title">
                    <span>{{item.equipCode}}</span>                    
                    <span class="right-tag" :class="{normal: item.statusName == '调试完成' && !item.errorRecord, 
                    exception: item.statusName == '调试完成' && item.errorRecord, waiting: item.statusName == '等待调试',
                        debugging: item.statusName == '正在调试'}">{{item.statusName == '调试完成'? (item.errorRecord? '异常':'正常') : item.statusName}}</span>
                </div>
                <div class="item-text" v-if="item.statusName == '正在调试' || item.statusName == '调试完成'">
                    <span>调试时间: </span><span :class="{'red-text': item.thPeriod > item.period}">{{item.period | formatDecimal(0)}}H</span>
                    <span style="margin-left: 0.5rem">调试油耗: </span><span :class="{'red-text': item.thDiesel > item.diesel}">{{item.diesel | formatDecimal(0)}}L</span>
                </div>
                <div class="item-text" v-if="item.statusName == '调试完成'">
                    <span>参数合规率: </span><span v-if="item.belowGrdNum > 0" class="red-text">{{item.belowGrdNum}}项不合规</span><span v-else>合规</span>
                    <span style="margin-left: 0.5rem">数据准确率: </span><span v-if="item.unAccGrdNum > 0" class="red-text">{{item.unAccGrdNum}}项不合规</span><span v-else>合规</span>
                </div>
                <div class="item-text" v-if="item.statusName == '正在调试' && !hideStatusTag"><span>调试开始时间: </span><span>{{item.startDebugTime}}</span></div>
                <div class="item-text" v-if="item.statusName == '等待调试' && !hideStatusTag"><span>下线时间: </span><span>{{item.offlineTime}}</span></div>
            </div>
        </div>
        <div v-if="item.statusName == '调试完成'">            
            <div style="margin-top: 15px;">调试完成时间: {{item.endDebugTime}}</div>
            <score-tag v-if="!hideStatusTag" :info="{num: item.debugScore}" style="position: absolute;bottom: 5px;right: 0;"></score-tag>
        </div>
        <div v-if="canSelect" class="selected-box" :class="{'selected-box--selected': item._isSelected}">
            <div class="selected-box__bg">
            </div>
            <van-icon name="success" class="selected-box__icon"></van-icon>
        </div>
    </div>
</template>

<script>
import scoreTag from './ScoreTag'
export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        hideStatusTag: {
            type: Boolean
        },
        canSelect: {
            type: Boolean
        }
    },
    computed: {
        deviceImageUrl(){
            switch(this.item.equipType){
                case '泵车': return require("../assets/device/bc.jpeg");
                case '车载泵': return require("../assets/device/czb.jpeg");
                case '拖泵': return require("../assets/device/tb.jpeg");
                case '湿喷机': return require("../assets/device/psj.jpeg");
                default: return null
            }
        }
    },
    components: {scoreTag},
    methods: {
        itemClick(){
            this.$emit("rowClick")
        }
    }
}
</script>

<style lang="scss" scoped>
    .device-item{
        position: relative;
        font-size: 12px;
        text-align: left;
        color: #8c8c8c;
    }
    .img-con{
        width: 4.25rem;
        height: 4.25rem;
        background: #f2f2f2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            height: 100%;
            &.empty{                
                width: 1.625rem;
                height: 1.25rem;
            }
        }
    }
    .mid-con{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 8px;
        .item-title{
            font-size: 17px;
            color: #333333;
        }
    }
    .right-tag{
        font-size: 12px;
        float: right;
        height: 1.5rem;
        line-height: 1.5rem;
        border-radius: 12px;
        padding: 0 .625rem;
        &.normal{
            background: #3f83f8;
            color: #fff;
        }
        &.exception{
            background: #f05252;
            color: #fff;
        }
        &.waiting{
            background: #def7ec;
            color: #0e9f6e;
        }
        &.debugging{
            background: #e1effe;
            color: #3f83f8;            
        }
    }
    .flex-buttom-line{
        display: flex;
        align-items: center;
    }
    .red-text{
        color: #f05252;
    }
    .selected-box{
        position: absolute;
        right: 0;
        bottom: 0;        
        border-radius: 0 0 10px 0;
        overflow: hidden;
        .selected-box__bg{
            border-bottom: 40px solid #e0e0e0;
            border-left: 40px solid transparent;
            width: 0;
            height: 0;
        }
        &.selected-box--selected .selected-box__bg{
            border-bottom: 40px solid #3f83f8;
        }
        .selected-box__icon{            
            position: absolute;
            bottom: 5px;
            right: 5px;
            color: #fff;
            font-size: 16px;
        }
    }
</style>